<template>
    <div class="PurchaseOrderDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small" :inline="true">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-input :value="form.deptTypeName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="进货机构">
                            <el-input :value="form.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="供应商">
                            <el-input :value="form.supplierName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="form.createTime" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <ef-remark v-model="form.remark" readonly />
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
            </div>
        </el-card>
        <PickupReturnAudit ref="PickupReturnAudit" :reviewPrivilegeFlag="'menu.purchase.selfIn.review'" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
                show-summary
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180" fixed="left">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="150">
                    <template slot-scope="scope">
                        {{
                            skuByGoodsCode(scope.row.goodsCode)
                                .bars.map((d) => d)
                                .join('/')
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="采购价" width="80" v-if="showInPrice()">
                    <template slot-scope="scope">
                        {{ scope.row.showPrices }}
                    </template>
                </el-table-column>
                <template v-if="enabledBigUnit">
                    <el-table-column label="包装因子" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.packFactors }}
                        </template>
                    </el-table-column>
                    <el-table-column label="采购箱数" min-width="120" prop="bigUnitCounts" />
                    <el-table-column label="散装数量" min-width="120" prop="bulkCounts" />
                </template>
                <el-table-column label="采购总数" :render-header="rendHeader1" min-width="120" prop="counts" />
                <el-table-column
                    v-if="showInPrice()"
                    label="采购金额"
                    :render-header="rendHeader2"
                    min-width="100"
                    prop="purchaseShowMoney"
                />
                <el-table-column label="赠品数量" width="120" prop="giftCounts" />
                <el-table-column label="生产日期" width="170" min-width="120" prop="produceDates" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import MoneyUtils from 'js/MoneyUtils';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
import { get } from 'request/http';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SelfInDetail',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: { PickupReturnAudit, EfRemark },
    data() {
        return {
            baseUrl: '/purchase/selfIn',
            enabledBigUnit: false,
            form: {
                createTime: null,
                creator: '',
                deptType: null,
                deptTypeName: '',
                supplierName: '',
                deptName: '',
                deptCode: '',
                remark: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await get(`${this.baseUrl}/info_c/${this.code}`);
            Util.copyProperties(rst, this.form);
            //是否启用大单位权限
            this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        })();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                'produceDates',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return this.decimal(detailParam.counts)
                            .sub(this.decimal(detailParam.bigUnitCounts).mul(detailParam.packFactors))
                            .toNumber();
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseShowMoney', (none, detailParam) => {
                    const purchaseShowMoney = MoneyUtils.moneyToDb(
                        this.decimal(detailParam.counts)
                            .mul(detailParam.showPrices || 0)
                            .toNumber()
                    );
                    return this.form.deptType === DeptTypeEnum.REPOSITORY
                        ? MoneyUtils.moneyToThree(purchaseShowMoney)
                        : MoneyUtils.moneyToYuan(purchaseShowMoney);
                })
            );
            return rst;
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl, this.form.stockStatus);
        },
        rendHeader1(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['采购总数 = 采购箱数 * 包装因子 + 散装数量'] },
                          })
                        : null,
                ]
            );
        },
        rendHeader2(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['采购金额 = 采购价 * 采购总数'] },
                          })
                        : null,
                ]
            );
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
